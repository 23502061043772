<template>
  <v-card>
    <v-card-title class="mt-n3">
      <span class="primary--text">{{ formEditTitle + proveedor }}</span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="itemsLogAutorizaciones"
        :loding="loadingTable"
        height="100%"
        class="elevation-1"
        item-key="mprovConfigAutoId"
      >
      </v-data-table>
    </v-card-text>
    <v-card-actions class="mt-0 mr-4">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";

export default {
  name: "VerLogAutorizaciones",
  props: {
    mProvId: { type: Number, required: true },
    proveedor: { type: String, required: false, default: "" },
  },
  data: (vm) => ({
    headers: [
      {
        text: "Estado",
        sortable: false,
        value: "estado",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "fechaToShow",
      },
      {
        text: "Usuario",
        sortable: false,
        value: "usuario",
      },
    ],
    itemsLogAutorizaciones: [],
    loadingTable: false,
    formEditTitle: "Log autorizaciones de proveedor: ",
  }),
  created() {
    this.loadLogAutorizaciones();
  },
  methods: {
    ...mapActions({
      getHistorialAutorizacionesPorComprobante:
        "proveedores/getHistorialAutorizacionesPorComprobante",
    }),
    async loadLogAutorizaciones() {
      try {
        this.loadingTable = true;
        const response = await this.getHistorialAutorizacionesPorComprobante(
          this.mProvId
        );
        if (response) {
          this.itemsLogAutorizaciones = response;
        }
      } catch (err) {}
      this.loadingTable = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>
<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="10" class="py-0">
            <PageHeader :title="title" />
          </v-col>
          <v-col v-if="canGoConfig" cols="2" class="pt-0 pb-0 py-0">
            <v-tooltip left class="pt-10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigAutorizaciones()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a configuración de autorizaciones</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pb-0 mb-0">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="empresaSelected"
                      :items="empresasItems"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      :rules="rules.required"
                      label="Empresa"
                      @change="setTipoCompsByEmpresa()"
                    ></v-autocomplete>
                  </v-col>
                  <!-- tipo de fecha -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="itemsTiposFecha"
                      return-object
                      label="Tipo de fecha"
                      item-text="value"
                      item-value="id"
                      :disabled="
                        letraComprobante !== null &&
                        letraComprobante !== '' &&
                        sucursalComprobante !== null &&
                        sucursalComprobante !== '' &&
                        numeroComprobante !== null &&
                        numeroComprobante !== '' &&
                        tipoComprobanteSelected !== null &&
                        tipoComprobanteSelected !== undefined
                      "
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha desde -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          :disabled="
                            letraComprobante !== null &&
                            letraComprobante !== '' &&
                            sucursalComprobante !== null &&
                            sucursalComprobante !== '' &&
                            numeroComprobante !== null &&
                            numeroComprobante !== '' &&
                            tipoComprobanteSelected !== null &&
                            tipoComprobanteSelected !== undefined
                          "
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde =
                              helpers.parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            tipoComprobanteSelected &&
                            letraComprobante &&
                            sucursalComprobante &&
                            numeroComprobante
                              ? []
                              : rules.required.concat(
                                  rules.validDate,
                                  rules.validDateRange(fechaDesde, fechaHasta),
                                  isValidRange ||
                                    'El intervalo no puede ser mayor a 6 meses'
                                )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="
                          fechaDesdeSelected = helpers.formatDate(fechaDesde)
                        "
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          :disabled="
                            letraComprobante !== null &&
                            letraComprobante !== '' &&
                            sucursalComprobante !== null &&
                            sucursalComprobante !== '' &&
                            numeroComprobante !== null &&
                            numeroComprobante !== '' &&
                            tipoComprobanteSelected !== null &&
                            tipoComprobanteSelected !== undefined
                          "
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaHasta =
                              helpers.parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            tipoComprobanteSelected &&
                            letraComprobante &&
                            sucursalComprobante &&
                            numeroComprobante
                              ? []
                              : rules.required.concat(
                                  rules.validDate,
                                  rules.validDateRange(fechaDesde, fechaHasta),
                                  isValidRange ||
                                    'El intervalo no puede ser mayor a 6 meses'
                                )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="
                          fechaHastaSelected = helpers.formatDate(fechaHasta)
                        "
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="py-0 pr-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      label="Código proveedor"
                      v-model.trim="provCod"
                      :rules="[rules.maxLength(provCod, 10)]"
                      @change="setProveedor()"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="toggleModalBusquedaProv"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar proveedor por nombre, CUIT o código</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- DATOS PROVEEDOR -->
                  <v-col cols="12" md="2" class="py-0">
                    <v-tooltip top :disabled="provNom == null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          filled
                          disabled
                          readonly
                          label="Proveedor"
                          hide-details=""
                          :loading="proveedoresLoading"
                          v-model.trim="provNom"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </template>
                      <span>{{ provNom ? provNom : "" }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0 pl-1">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="value"
                      item-value="id"
                      :loading="loadingTipoComp"
                      return-object
                      :disabled="empresaSelected == null"
                      label="Tipo de comprobante"
                      outlined
                      @change="clearFechas"
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- LETRA -->
                  <v-col cols="12" md="1" class="py-0 p-0">
                    <v-text-field
                      v-model="letraComprobante"
                      :hide-details="true"
                      dense
                      outlined
                      v-mask="'A'"
                      @change="clearFechas"
                      label="Letra"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- SUCURSAL -->
                  <v-col class="py-0 p-0" cols="12" md="1">
                    <v-text-field
                      v-model="sucursalComprobante"
                      :hide-details="true"
                      dense
                      outlined
                      @change="clearFechas"
                      label="Sucursal"
                      v-mask="'#####'"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- NUMERO COMPROBANTE -->
                  <v-col class="py-0 pr-n2 pl-0" cols="12" md="2">
                    <v-text-field
                      v-model="numeroComprobante"
                      dense
                      outlined
                      :rules="
                        numeroComprobante
                          ? [rules.decimalGreaterThanZero(numeroComprobante)]
                          : []
                      "
                      label="N°"
                      @change="clearFechas"
                      v-mask="'#########'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n2">
                  <!-- Tipos proveedor -->
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoProveedorSelected"
                      :items="tiposProveedoresItems"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Tipo proveedor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pt-0 py-0 mt-n6">
                    <v-radio-group
                      v-model="condicionAutorizacion"
                      mandatory
                      class="custom-radio-group p-0 pt-0"
                    >
                      <v-radio
                        class="custom-radio py-0 mb-1"
                        label="Pendientes de autorizar"
                        value="P"
                      ></v-radio>
                      <v-radio
                        class="custom-radio py-0"
                        label="Solo autorizados"
                        value="A"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- fecha autorización desde -->
                  <v-col
                    v-if="condicionAutorizacion === 'A'"
                    cols="3"
                    sm="3"
                    md="3"
                    class="py-0"
                  >
                    <v-menu
                      ref="menu1"
                      v-model="menuFechaAuto2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaAutorizaDesdeSelected"
                          label="Fecha autorización desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaAutorizaDesde = helpers.parseDateToIso(
                              fechaAutorizaDesdeSelected
                            )
                          "
                          v-on="on"
                          :rules="
                            condicionAutorizacion === 'A'
                              ? rules.required.concat(rules.validDate, [
                                  new Date(
                                    helpers.parseDateToIso(
                                      fechaAutorizaDesdeSelected
                                    )
                                  ) <=
                                    new Date(
                                      helpers.parseDateToIso(
                                        fechaAutorizaHastaSelected
                                      )
                                    ) || 'Formato incorrecto',
                                ])
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaAutorizaDesde"
                        no-title
                        @change="
                          fechaAutorizaDesdeSelected =
                            helpers.formatDate(fechaAutorizaDesde)
                        "
                        @input="menuFechaAuto2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha autorización hasta -->
                  <v-col
                    v-if="condicionAutorizacion === 'A'"
                    cols="3"
                    sm="3"
                    md="3"
                    class="py-0"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="menuFechaAuto1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaAutorizaHastaSelected"
                          label="Fecha autorización hasta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaAutorizaHasta = helpers.parseDateToIso(
                              fechaAutorizaHastaSelected
                            )
                          "
                          v-on="on"
                          :rules="
                            condicionAutorizacion === 'A'
                              ? rules.required.concat(
                                  rules.validDate,
                                  new Date(
                                    helpers.parseDateToIso(
                                      fechaAutorizaDesdeSelected
                                    )
                                  ) <=
                                    new Date(
                                      helpers.parseDateToIso(
                                        fechaAutorizaHastaSelected
                                      )
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaAutorizaHasta"
                        no-title
                        @change="
                          fechaAutorizaHastaSelected =
                            helpers.formatDate(fechaAutorizaHasta)
                        "
                        @input="menuFechaAuto1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" align="end" class="text-right py-0 pb-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-data-table
          :headers="headersAutorizaciones"
          :items="itemsAutorizaciones"
          height="100%"
          class="elevation-1"
          :loading="isLoadingTable"
          show-expand
          show-select
          :search="search"
          v-model="autorizacionesSelected"
          :expanded.sync="expanded"
          item-key="mProvId"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalLog(item)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver log autorizaciones</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{ isExpanded ? chevronUpIcon : chevronDownIcon }}</v-icon
                >
              </template>
              <span>Observación</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Observación:</strong>
              {{ item.mensaje }}
            </td>
          </template>
        </v-data-table>
        <v-col cols="3" class="p-0 pt-2">
          <v-btn
            @click="autorizarFacturas()"
            color="primary"
            elevation="2"
            :loading="loadingBtn"
            :disabled="autorizacionesSelected.length === 0"
          >
            {{
              condicionAutorizacion === "P"
                ? "Autorizar"
                : "Anular autorización"
            }}
          </v-btn>
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pb-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
    <!-- modal excel de errores -->
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="55%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros con error.
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="openModalSeeLog"
      v-model="openModalSeeLog"
      max-width="65%"
      @keydown.esc="openModalSeeLog = false"
      persistent
    >
      <VerLogAutorizaciones
        :mProvId="idToSeeLog"
        :proveedor="proveedorToSeeLog"
        @closeAndReload="closeAndReload"
      >
      </VerLogAutorizaciones>
    </v-dialog>
  </v-container>
</template>


<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import VerLogAutorizaciones from "@/components/modules/proveedores/VerLogAutorizaciones.vue";

export default {
  name: "AutorizacionesProveedor",
  components: {
    Ayuda,
    GoBackBtn,
    FiltersSelected,
    PageHeader,
    BuscarProveedor,
    VerLogAutorizaciones,
  },
  directives: { mask },
  data: (vm) => ({
    rules: rules,
    helpers: helpers,
    settingsIcon: enums.icons.SETTINGS,
    optionCode: enums.webSiteOptions.AUTORIZACION_FACTURAS_PROVEEDOR,
    routeToGo: "ComprobantesProveedor",
    title: enums.titles.AUTORIZACIONES_PROVEEDOR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    searchIcon: enums.icons.SEARCH,
    seeIcon: enums.icons.SEE,
    search: "",
    showExpand: false,
    showHelp: false,
    showFilters: true,
    isFormValid: false,
    expanded: [],
    empresaSelected: null,
    condicionAutorizacion: null,
    provCod: null,
    provNom: null,
    provId: null,
    autorizacionesSelected: [],
    headersAutorizaciones: [
      {
        text: "Proveedor",
        sortable: false,
        value: "provCodNombre",
      },
      {
        text: "Comprobante",
        sortable: false,
        value: "comprobante",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "fechaToShow",
      },
      {
        text: "Fecha vencimiento",
        sortable: false,
        align: "right",
        value: "fechaVencToShow",
      },
      {
        text: "Moneda",
        sortable: false,
        align: "left",
        value: "moneda",
      },
      {
        text: "Importe",
        sortable: false,
        align: "right",
        value: "totalToShow",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
      { text: "", align: "end", value: "data-table-expand" },
    ],
    itemsAutorizaciones: [],
    isLoadingTable: false,
    proveedoresLoading: false,
    buscarProveedorModal: false,
    tipoComprobantesItems: [],
    tipoComprobanteSelected: null,
    numeroComprobante: null,
    sucursalComprobante: null,
    letraComprobante: null,
    tipoProveedorSelected: null,
    tiposProveedoresItems: [],
    comprobantesAutorizarConErrores: [],
    empresasItems: [],
    filtersApplied: [],
    itemsTiposFecha: [
      { id: "C", value: "Fecha de contable" },
      { id: "F", value: "Fecha de comprobante" },
      { id: "V", value: "Fecha de vencimiento" },
    ],
    tipoFechaSelected: { id: "F", value: "Fecha comprobante" },
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: helpers.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: null,
    fechaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menu2: null,
    ///FECHAS AUTORIZACION DESDE/HASTA
    fechaAutorizaDesde: new Date(
      new Date().valueOf() - 1000 * 60 * 60 * 24 * 10
    )
      .toISOString()
      .substr(0, 10),
    fechaAutorizaDesdeSelected: helpers.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 10)
        .toISOString()
        .substr(0, 10)
    ),
    menuFechaAuto1: null,
    fechaAutorizaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaAutorizaHasta: new Date().toISOString().substr(0, 10),
    menuFechaAuto2: null,
    canGoConfig: false,
    loadingBtn: false,
    modalExportarExcel: false,
    idToSeeLog: null,
    openModalSeeLog: false,
    loadingTipoComp: false,
  }),
  computed: {
    isValidRange() {
      if (!this.fechaDesde || !this.fechaHasta) return true;
      const parsedFechaDesde = new Date(
        this.helpers.parseDateToIso(this.fechaDesdeSelected)
      );
      const parsedFechaHasta = new Date(
        this.helpers.parseDateToIso(this.fechaHastaSelected)
      );
      const diffMonths =
        (parsedFechaHasta.getFullYear() - parsedFechaDesde.getFullYear()) * 12 +
        parsedFechaHasta.getMonth() -
        parsedFechaDesde.getMonth();
      // Validar si la diferencia es menor o igual a 6 meses
      return diffMonths <= 6;
    },
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setSelects();
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      consultaFacturasParaAutorizar:
        "proveedores/consultaFacturasParaAutorizar",
      getEntidadesFacturantesByUsersAutoriza:
        "devengamientos/getEntidadesFacturantesByUsersAutoriza",
      autorizarAnularFacturas: "proveedores/grabarAutorizarAnularFacturas",
      getTipoComprobantesFacturaContado:
        "proveedores/getTipoComprobantesFacturaContado",
      fetchTiposProveedores: "proveedores/fetchTiposProveedores",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.webSiteOptions.AUTORIZACION_FACTURAS_PROVEEDOR_CONFIG:
            this.canGoConfig = true;
            break;
          default:
            break;
        }
      });
    },
    async setSelects() {
      const empresas = await this.getEntidadesFacturantesByUsersAutoriza();
      this.empresasItems = empresas;
      if (empresas?.length == 1) {
        this.empresaSelected = this.empresasItems[0];
        this.setTipoCompsByEmpresa();
      }
      const tiposProveedores = await this.fetchTiposProveedores();
      this.tiposProveedoresItems = tiposProveedores;
    },
    clearFechas() {
      if (
        this.letraComprobante &&
        this.sucursalComprobante &&
        this.numeroComprobante &&
        this.tipoComprobanteSelected
      ) {
        this.tipoFechaSelected = {};
        this.fechaDesde = null;
        this.fechaHasta = null;
        this.fechaDesdeSelected = null;
        this.fechaHastaSelected = null;
      }
    },
    async setTipoCompsByEmpresa() {
      if (this.empresaSelected) {
        this.loadingTipoComp = true;
        const tipoComprobantes = await this.getTipoComprobantesFacturaContado(
          this.empresaSelected.id
        );
        this.tipoComprobantesItems = tipoComprobantes;
        this.loadingTipoComp = false;
      } else this.tipoComprobanteSelected = null;
    },
    openModalLog(item) {
      this.openModalSeeLog = true;
      this.idToSeeLog = item.mProvId;
      this.proveedorToSeeLog = item.provCodNombre;
    },
    goToConfigAutorizaciones() {
      this.$router.push({
        name: "ConfiguracionAutorizacionesProveedor",
      });
    },
    findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async applyFilters() {
      this.autorizacionesSelected = [];
      this.showFilters = false;
      this.customizeFiltersApplied();
      this.isLoadingTable = true;
      const data = {
        entFacId: this.empresaSelected?.id ?? null,
        tipoFecha: this.tipoFechaSelected?.id ?? "",
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        provId: this.provId ?? 0,
        tCompId: this.tipoComprobanteSelected?.id ?? 0,
        letra: this.letraComprobante ?? "",
        suc: this.sucursalComprobante ?? 0,
        numero: this.numeroComprobante ?? 0,
        tipoProvId: this.tipoProveedorSelected?.id ?? -1,
        opcion: this.condicionAutorizacion,
        fechaDesdeAuto:
          this.condicionAutorizacion === "A" ? this.fechaAutorizaDesde : null,
        fechaHastaAuto:
          this.condicionAutorizacion === "A" ? this.fechaAutorizaHasta : null,
      };
      try {
        const response = await this.consultaFacturasParaAutorizar(data);
        if (response) this.itemsAutorizaciones = response;
      } catch (err) {}
      this.isLoadingTable = false;
    },
    setProveedor() {
      if (
        this.provCod == null ||
        this.provCod == undefined ||
        this.provCod == ""
      ) {
        this.provId = null;
        this.proveedorSelected = null;
        this.provNom = null;
        this.provCod = null;
      } else this.findProveedorByCodigo();
    },
    async findProveedorByCodigo() {
      try {
        this.proveedoresLoading = true;

        const proveedores = await this.getProveedoresByCod(this.provCod);
        if (proveedores?.length === 0) {
          this.setAlert({
            type: "warning",
            message: "Proveedor inexistente",
          });
          this.proveedorSelected = null;
          this.provNom = null;
          this.proveedoresLoading = false;
          return;
        }
        this.proveedoresLoading = false;
        this.proveedorSelected = proveedores[0];
        this.provId = this.proveedorSelected.id;
        this.provNom = this.proveedorSelected.value1;
      } catch (error) {
        this.proveedoresLoading = false;
        this.proveedorSelected = null;
        this.provId = null;
      }
    },
    async autorizarFacturas() {
      this.loadingBtn = true;
      const data = {
        estado: this.condicionAutorizacion === "A" ? "U" : "A",
        listaMprovId: this.autorizacionesSelected.map((x) => x.mProvId),
      };
      try {
        const response = await this.autorizarAnularFacturas(data);
        if (response) {
          if (response.length > 0) {
            this.comprobantesAutorizarConErrores = response;
            this.modalExportarExcel = true;
          } else
            this.setAlert({
              type: "success",
              message: `Facturas ${
                data.estado == "A" ? "autorizadas" : "anuladas"
              } con éxito`,
            });
        } else {
          this.setAlert({
            type: "error",
            message: "Ocurrio un error",
          });
        }
      } catch (err) {}
      this.loadingBtn = false;
      this.autorizacionesSelected = [];
      this.applyFilters();
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "empresaSelected.value",
          label: "Empresa",
          model: this.empresaSelected.value,
        });
      }
      if (this.tipoFechaSelected?.value) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected?.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.provCod && this.provNom) {
        this.filtersApplied.splice(5, 1, {
          key: "proveedorSelected",
          label: "Proveedor",
          model: `${this.provCod} - ${this.provNom}`,
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(6, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected.value,
        });
      }
      if (
        this.letraComprobante ||
        this.sucursalComprobante ||
        this.numeroComprobante
      ) {
        this.filtersApplied.splice(7, 1, {
          key: "numeroComprobante",
          label: "Número comprobante",
          model: `${this.letraComprobante ?? ""} - ${
            this.sucursalComprobante ?? ""
          } - ${this.numeroComprobante ?? ""}`,
        });
      }
      if (this.tipoProveedorSelected) {
        this.filtersApplied.splice(8, 1, {
          key: "tipoProveedorSelected",
          label: "Tipo proveedor",
          model: this.tipoProveedorSelected.value,
        });
      }
      if (this.condicionAutorizacion) {
        this.filtersApplied.splice(9, 1, {
          key: "condicionAutorizacion",
          label:
            this.condicionAutorizacion === "P"
              ? "Pendientes autorizar"
              : "Solo autorizados",
          model: "SI",
        });
      }
      if (
        this.fechaAutorizaDesdeSelected &&
        this.condicionAutorizacion === "A"
      ) {
        this.filtersApplied.splice(10, 1, {
          key: "fechaAutorizaDesdeSelected",
          label: "Fecha autorización desde",
          model: this.fechaAutorizaDesdeSelected,
        });
      }
      if (
        this.fechaAutorizaHastaSelected &&
        this.condicionAutorizacion === "A"
      ) {
        this.filtersApplied.splice(11, 1, {
          key: "fechaAutorizaHastaSelected",
          label: "Fecha autorización hasta",
          model: this.fechaAutorizaHastaSelected,
        });
      }
    },
    exportExcelError() {
      let result = [];
      this.comprobantesAutorizarConErrores.forEach((x) =>
        result.push({
          ["MProvId"]: x.MProvId,
          ["Comprobante"]: x.Comprobante,
          ["Proveedor"]: `${x.ProvCod} ${x.ProvNombre}`,
          ["Fecha"]: helpersExcel.translateDate(x.Fecha),
          ["Fecha Vencimiento"]: helpersExcel.translateDate(x.FechaVenc),
          ["Moneda"]: x.Moneda,
          ["Total"]: x.Total,
          ["OP"]: x.OP,
          ["Mensaje"]: x.Mensaje,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Errores registros");
    },
    closeAndReload() {
      this.openModalSeeLog = false;
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>


<style scoped>
.custom-radio-group ::v-deep .v-radio-group__label {
  font-size: 3;
}
.custom-radio ::v-deep .v-radio__label {
  font-size-adjust: 5px;
}
</style>